// src/pages/ProfilePage.js
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './ProfilePage.css';

const ProfilePage = () => {
  const [user, setUser] = useState(null); // Состояние для пользователя
  const [purchases, setPurchases] = useState([]); // Состояние для покупок

  useEffect(() => {
    // Запрос для получения текущего пользователя
    fetch('/api/user', { credentials: 'include' })
      .then(res => {
        if (!res.ok) {
          throw new Error('Необходимо войти в систему');
        }
        return res.json();
      })
      .then(userData => {
        setUser(userData);
        // Запрашиваем покупки пользователя
        return fetch(`/purchase/user/${userData.id}`, { credentials: 'include' });
      })
      .then(res => {
        if (!res.ok) {
          throw new Error(`Ошибка: ${res.statusText}`);
        }
        return res.json();
      })
      .then(data => {
        setPurchases(data.purchases);
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Ошибка: ' + error.message);
      });
  }, []);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Номер заказа скопирован в буфер обмена.');
      })
      .catch(err => {
        console.error('Ошибка при копировании:', err);
        alert('Не удалось скопировать номер заказа.');
      });
  };
  

  return (
    <div className="profile-page">
      <Header />
      <div className="user-purchase-content">
        {user ? (
          <>
            <div className="profile-info">
              <h1>Профиль пользователя</h1>
              <img src={user.picture} alt="User Profile" className="profile-photo" />
              <p><strong>Имя:</strong> {user.firstName} {user.lastName}</p>
              <p><strong>Email:</strong> {user.email}</p>
            </div>
            <div className="user-purchases">
  <h2>Мои покупки</h2>
  {purchases.length > 0 ? (
    <div className="purchases-container">
      {purchases.map((purchase) => (
        <div key={purchase.id} className="purchase-card">
          <div className="purchase-header">
            <p className="purchase-id">
              <strong>ID Покупки:</strong> {purchase.id}
            </p>
            <button
              onClick={() => copyToClipboard(purchase.id)}
              className="copy-id-button"
            ></button>
          </div>
          <div className="purchase-content">
            <p><strong>Товары:</strong></p>
            <ul className="items-list">
              {purchase.items.map((item, index) => (
                <li key={index} className="item">
                  {item.title} (x{item.quantity}) - {item.complete ? 'Выполнено нами' : 'Ожидайте выполнение заказа'}
                </li>
              ))}
            </ul>
            <p>
              <strong>Оплачено:</strong> {purchase.isPayed ? "Да" : "Нет"}
            </p>
            <p>
              <strong>Заказ полностью выполнен нами:</strong> {purchase.isCompleted ? "Да" : "Нет"}
            </p>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p>У вас пока нет покупок.</p>
  )}
</div>

          </>
        ) : (
          <div className="no-profile">
            <h1>Вы не авторизованы</h1>
            <p>У вас нет профиля. Пожалуйста, войдите в систему.</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePage;
