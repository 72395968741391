// src/pages/HomePage.js
import React from 'react';
import GameCard from '../components/GameCard';
import Header from '../components/Header';
import './HomePage.css';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <div className="home-page">
      <Header />
      <div className='content'>
      <h1>PP</h1>
      <h2>Welcome</h2>
      </div>
      
      <div className="game-cards-container">
      <GameCard image="/img/rust_card.jpg" title="Rust" link="/rust" />
      <GameCard image="/img/pubg_card.jpg" title="Pubg Mobile" link="/pubg" />
      <GameCard image="/img/cs_card.jpg" title="CS 2" link="/cs2" />
      </div>
      <Footer/>
      <div>
{/* kassa */}
      </div>
    </div>
  );
};

export default HomePage;