// src/pages/RustPage.js
import React, {useEffect} from 'react';
import Header from '../components/Header';
import ServiceCard from '../components/ServiceCard';
import UnderHeader from '../components/UnderHeader';
import Footer from '../components/Footer';

function RustPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы до начала
  }, []);
  return (
    <div className="service-page">
      <Header/>
      <UnderHeader 
      title="Services">

      </UnderHeader>
      <div className="services">
        <ServiceCard
        image="/img/rust/rust_skins.jpg"
        title="F"
        description="F"
            link="/rust/skin-shop"
      />
      <ServiceCard
        image="/img/rust/rust_raid.jpg"
        title="F"
        description="F"
            link="/rust/raid-helper"
      />
      <ServiceCard
        image="/img/rust/rust_farm.jpg"
        title="F"
        description="F"
            link="/rust/farmbot"
      />
      <ServiceCard
        image="/img/rust/rust_night_guard.jpg"
        title="F"
        description="Впадлу текст писать"
            link="/rust/night-guard"
      />
      <ServiceCard
        image="/img/rust/rust_twitch_drops.jpg"
        title="F"
        description="Впадлу текст писать"
            link="/rust/twitch-drops"
      />
      </div>
      <Footer/>
    </div>
  );
}

export default RustPage;