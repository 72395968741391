// src/pages/services/pubg_mobile/MetroEscort.js
import React, { useEffect } from 'react';
import Header from '../../../components/Header';
import UnderHeader from '../../../components/UnderHeader';
import Footer from '../../../components/Footer';
import ServiceInfoCard from '../../../components/ServiceInfoCard';
import { addToCart } from '../../../utils/cartUtils';

function MetroEscort() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы до начала
  }, []);

  return (
    <div className="service-page">
      <Header />
      <UnderHeader title="r"></UnderHeader>
      <div className="services">
        <ServiceInfoCard
          title="r"
          description="r"
          price="1"
          onAddToCart={addToCart}
        />
      </div>
      <Footer />
    </div>
  );
}

export default MetroEscort;
